import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { FCIcon } from './typedefs';

export const IconBadgeProfessionFullstack: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M7 11.1666C4.69875 11.1666 2.83333 9.30123 2.83333 6.99998C2.83333 4.69873 4.69875 2.83331 7 2.83331C9.30125 2.83331 11.1667 4.69873 11.1667 6.99998C11.1667 9.30123 9.30125 11.1666 7 11.1666ZM6.04583 10.1946C5.63475 9.32261 5.39661 8.37924 5.34458 7.41665H3.69249C3.77357 8.05785 4.03913 8.66171 4.4569 9.15486C4.87467 9.648 5.42667 10.0092 6.04583 10.1946ZM6.17916 7.41665C6.24208 8.4329 6.5325 9.38748 7 10.23C7.48013 9.36521 7.7606 8.40393 7.82083 7.41665H6.17916ZM10.3075 7.41665H8.65541C8.60338 8.37924 8.36524 9.32261 7.95416 10.1946C8.57332 10.0092 9.12532 9.648 9.54309 9.15486C9.96086 8.66171 10.2264 8.05785 10.3075 7.41665ZM3.69249 6.58331H5.34458C5.39661 5.62072 5.63475 4.67735 6.04583 3.8054C5.42667 3.99076 4.87467 4.35196 4.4569 4.8451C4.03913 5.33825 3.77357 5.94211 3.69249 6.58331ZM6.17958 6.58331H7.82041C7.76031 5.59606 7.47998 4.63478 7 3.76998C6.51986 4.63475 6.23939 5.59603 6.17916 6.58331H6.17958ZM7.95416 3.8054C8.36524 4.67735 8.60338 5.62072 8.65541 6.58331H10.3075C10.2264 5.94211 9.96086 5.33825 9.54309 4.8451C9.12532 4.35196 8.57332 3.99076 7.95416 3.8054Z" fill={BADGE_ICON_COLOR} />
  </BaseIcon>
);
