import React from 'react';
import { IconEmojiBase } from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase';
import { FCImage } from '@/components/ui/IconEmojies/typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';
import spaceInvader from '@/images/generated/icons.emoji.spaceInvader.s3Key';

export const IconEmojiSpaceInvader: FCImage = ({
  size = 16,
  className,
  alt = 'Space invader',
}) => (
  <IconEmojiBase className={className}>
    <IconImage
      imageData={spaceInvader}
      width={size}
      height={size}
      alt={alt}
    />
  </IconEmojiBase>
);
