import { useMemo } from 'react';
import { useUserSubscriptionsQuery } from '@/components/platform/Subscription/graphql/generated/userSubscription.query.generated';

type UseActiveSubscriptionProps = {
  skip?: boolean;
};
export const useActiveSubscription = ({
  skip,
}: UseActiveSubscriptionProps) => {
  const { data } = useUserSubscriptionsQuery({
    skip,
    variables: {
      filter: {
        started: true,
        expired: false,
      },
    },
  });

  return useMemo(
    () => (data?.userSubscriptions ?? []).find(
      (subscription) => !subscription.expired,
    ),
    [data?.userSubscriptions],
  );
};
