import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconStarFourPoints: FCIcon = (props) => {
  // Used to prevent id conflicts when using this component multiple times on the same page
  const clipId = `clip_${Math.floor(Math.random() * 10000)}`;

  return (
    <BaseIcon
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_57495_364377)">
        <path
          d="M5.0013 0.414062L3.7513 3.7474L0.417969 4.9974L3.7513 6.2474L5.0013 9.58073L6.2513 6.2474L9.58464 4.9974L6.2513 3.7474L5.0013 0.414062Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            width="10"
            height="10"
            fill="currentColor"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};
