import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconCommentStatus: FCIcon = (props) => (
  <BaseIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.30301 12.6667L1.33301 15V2.66667C1.33301 2.48986 1.40325 2.32029 1.52827 2.19526C1.65329 2.07024 1.82286 2 1.99967 2H13.9997C14.1765 2 14.3461 2.07024 14.4711 2.19526C14.5961 2.32029 14.6663 2.48986 14.6663 2.66667V12C14.6663 12.1768 14.5961 12.3464 14.4711 12.4714C14.3461 12.5964 14.1765 12.6667 13.9997 12.6667H4.30301ZM3.84167 11.3333H13.333V3.33333H2.66634V12.2567L3.84167 11.3333Z" fill="currentColor" />
    <rect x="4.66699" y="6.66667" width="1.33333" height="1.33333" fill="currentColor" />
    <rect x="7.33301" y="6.66667" width="1.33333" height="1.33333" fill="currentColor" />
    <rect x="10" y="6.66667" width="1.33333" height="1.33333" fill="currentColor" />
  </BaseIcon>
);
