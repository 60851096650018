import React, {
  Dispatch, FC, SetStateAction, useCallback,
} from 'react';
import { Form } from 'react-final-form';
import { UpdateStatusFormData } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/typedefs';
import { UpdateStatusForm } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/UpdateStatusForm';
import { useUpdateStatus } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/useUpdateStatus';

interface Props {
  status?: string | null;
  setIsStatusUpdating: Dispatch<SetStateAction<boolean>>;
  userId?: number | null;
  isAdminProfile?: boolean;
}

export const UpdateStatus: FC<Props> = ({
  status,
  setIsStatusUpdating,
  userId,
  isAdminProfile,
}) => {
  const cancelUpdate = useCallback(
    () => {
      setIsStatusUpdating(false);
    },
    [setIsStatusUpdating],
  );

  const { handleUpdate } = useUpdateStatus({
    closeForm: cancelUpdate,
    userId,
    isAdminProfile,
  });

  return (
    <Form<UpdateStatusFormData>
      subscription={{
        submitting: true,
        dirty: true,
        pristine: true,
        initialValues: true,
      }}
      initialValues={{
        status,
      }}
      onSubmit={handleUpdate}
      render={(formRenderProps) => (
        <UpdateStatusForm
          {...formRenderProps}
          cancelUpdate={cancelUpdate}
        />
      )}
    />
  );
};
