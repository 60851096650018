import React from 'react';
import { colorTokens } from '@/components/ui/colorTokens';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconMateAcademy: FCIcon = (props) => {
  const fillColor = colorTokens['bg-neutral'];

  return (
    <BaseIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z" fill="currentColor" />
      <path d="M12.5329 9.02457H10.9217L9.30136 11.7311L7.69009 9.02457H6.06976V15.361H7.69009V11.9574L9.21989 14.492H9.38282L10.9217 11.9484V15.361H12.5329V9.02457Z" fill={fillColor} />
      <path d="M16.6715 10.835V11.2514C16.3818 10.9074 15.9655 10.7083 15.3861 10.7083C14.1731 10.7083 13.2589 11.7402 13.2589 13.098C13.2589 14.4558 14.1731 15.4878 15.3861 15.4878C15.9655 15.4878 16.3818 15.2886 16.6715 14.9446V15.361H18.1742V10.835H16.6715ZM15.721 14.0847C15.1598 14.0847 14.7706 13.6864 14.7706 13.098C14.7706 12.5096 15.1598 12.1113 15.721 12.1113C16.2823 12.1113 16.6715 12.5096 16.6715 13.098C16.6715 13.6864 16.2823 14.0847 15.721 14.0847Z" fill={fillColor} />
    </BaseIcon>
  );
};
