import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { LatestTopicFragment } from '@/components/platform/UserProfile/graphql/generated/latestTopic.generated';
import { LatestTaskFragment } from '@/components/platform/UserProfile/graphql/generated/latestTask.generated';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { IconEmojiBooks } from '@/components/ui/IconEmojies/IconEmojiBooks';
import { IconEmojiBriefCase } from '@/components/ui/IconEmojies/IconEmojiBriefCase';
import styles from './CurrentProgress.module.scss';

interface Props {
  latestTopic?: LatestTopicFragment | null;
  latestTask?: LatestTaskFragment | null;
}

export const CurrentProgress: FC<Props> = React.memo(({
  latestTopic, latestTask,
}) => {
  const { t } = useTranslation([I18N_CODES.common, I18N_CODES.course]);

  if (!latestTopic && !latestTask) {
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      data-qa={UserProfileHarnessSelectors.CurrentProgressBlock}
    >
      <h3 className={cn(typography.platformH3, styles.title)} data-qa='current-progress-title'>
        {t(`${I18N_CODES.common}:user_profile.current_progress_block_title`)}
      </h3>

      {latestTopic && (
        <div className={cn('color-gray-50', typography.platformTextSecondary, styles.currentProgressItem)} data-qa='latest-topic'>
          <span className={styles.emojiWrapper}>
            <IconEmojiBriefCase />
          </span>
          <div className={styles.topicAndModuleInfo}>
            <p>
              <span className={styles.progressEntityType}>
                {t(`${I18N_CODES.common}:user_profile.current_progress_module`)}
              </span>
              {': '}
              <span className={styles.progressEntityName}>
                {t(`courses.python-basics-extended:${latestTopic.module.name}`)}
              </span>
            </p>

            <p>
              <span className={styles.progressEntityType}>
                {t(`${I18N_CODES.common}:user_profile.current_progress_topic`)}
              </span>
              {': '}
              <span className={styles.progressEntityName}>
                {latestTopic.name}
              </span>
            </p>
          </div>
        </div>
      )}

      {latestTask && (
        <div className={cn(typography.platformTextSecondary, styles.currentProgressItem)} data-qa='latest-solved-task'>
          <span className={styles.emojiWrapper}>
            <IconEmojiBooks />
          </span>
          <p>
            <span className={styles.progressEntityType}>
              {t(`${I18N_CODES.common}:user_profile.current_progress_task`)}
            </span>
            {': '}
            <span className={styles.progressEntityName}>
              {latestTask.name}
            </span>
          </p>
        </div>
      )}
    </div>
  );
});
