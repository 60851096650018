import { useCallback, useState } from 'react';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { analyticsSDK } from '@/controllers/analytics';
import { PaywallLocations } from '@/controllers/analytics/generated';

export type ClosePaywallOptions = {
  shouldDisableAnalyticEvent?: boolean;
};

interface Options {
  paywallLocation: PaywallLocations;
  onPaywallClose?: () => void;
}

export const usePaywall = (options: Options) => {
  const {
    paywallLocation,
    onPaywallClose,
  } = options;
  const [isPaywallOpened, setIsPaywallOpened] = useState(false);
  const { isSubscriptionProductDomain } = useSubDomainContext();

  const closePaywall = useCallback((closeOptions?: ClosePaywallOptions) => {
    const { shouldDisableAnalyticEvent } = closeOptions || {};

    if (!isSubscriptionProductDomain) {
      return;
    }

    setIsPaywallOpened(false);

    if (!shouldDisableAnalyticEvent) {
      analyticsSDK.subscriptionProduct.sendPaywallModalClosedEvent({
        paywallLocation,
      });
    }

    onPaywallClose?.();
  }, [
    isSubscriptionProductDomain,
    paywallLocation,
    setIsPaywallOpened,
    onPaywallClose,
  ]);

  const openPaywall = useCallback(() => {
    if (!isSubscriptionProductDomain) {
      return;
    }

    setIsPaywallOpened(true);

    analyticsSDK.subscriptionProduct.sendPaywallModalOpenedEvent({
      paywallLocation,
    });
  }, [isSubscriptionProductDomain, paywallLocation, setIsPaywallOpened]);

  return {
    isPaywallOpened,
    closePaywall,
    openPaywall,
  };
};
