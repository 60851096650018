import React, { FC } from 'react';
import { cn } from '@/lib';
import styles from '@/components/ui/IconEmojies/IconEmojiBase/IconEmojiBase.module.scss';

interface Props {
  className?: string;
}

export const IconEmojiBase: FC<Props> = (props) => {
  const {
    className,
    children,
  } = props;

  return (
    <div className={cn(styles.iconBaseStyles, className)} data-qa="icon-emoji-base">
      {children}
    </div>
  );
};
