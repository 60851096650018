import React, { FC, useEffect } from 'react';
import { analyticsSDK } from '@/controllers/analytics';
import { Achievements } from '@/components/platform/UserProfile/AchievementsBlock';
import { CurrentProgress } from '@/components/platform/UserProfile/CurrentProgressBlock';
import { SocialLinks } from '@/components/platform/UserProfile/SocialLinksBlock';
import { Statistic } from '@/components/platform/UserProfile/StatisticBlock';
import { UserProfileHeader } from '@/components/platform/UserProfile/UserProfileHeader';
import { UserProfileMainInfo } from '@/components/platform/UserProfile/UserProfileMainInfo';
import { Button } from '@/components/ui/Button';
import { Loader } from '@/components/ui/Loader';
import { IconClose } from '@/components/ui/icons/IconClose';
import { useClearLinks } from '@/components/platform/UserProfile/SocialLinksBlock/useClearLinks';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { useGetUserProfileQuery } from '@/components/platform/UserProfile/graphql/generated/getUserProfile.query.generated';
import { ACHIEVEMENTS_TO_DISPLAY } from '@/components/platform/UserProfile/constants';
import i18Next from '@/middleware/i18n';
import { useProfile } from '@/components/platform/UserProfile/hooks/useProfile';
import { PrivateProfileBadge } from '@/components/platform/UserProfile/PrivateProfileBadge';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import styles from './MainView.module.scss';

export const MainView: FC = React.memo(() => {
  const { userId, closeProfile, opener } = useUserProfileContext();
  const { pathname } = i18Next.useRouter();
  const { data, loading } = useGetUserProfileQuery({
    variables: {
      id: userId ?? 0,
      achievementsLimit: ACHIEVEMENTS_TO_DISPLAY + 1,
    },
    skip: !userId || userId < 0,
  });

  const {
    profile,
    isPrivate,
    isAdminProfile,
  } = useProfile(data?.getUserProfile);

  useEffect(() => {
    if (profile) {
      analyticsSDK.userProfile.sendOpenedEvent({
        pageUrl: pathname,
        opener,
      });
    }
  }, [profile, pathname, opener]);

  const isAtLeastOneLinkExists = useClearLinks(profile?.socialLinks).length > 0;

  return (
    <>
      <Loader loading={loading || !profile} />
      <div
        className={styles.wrapper}
        data-qa={UserProfileHarnessSelectors.MainView}
      >
        <Button
          mode={Button.mode.TransparentLight}
          size={Button.size.Small}
          onClick={closeProfile}
          data-qa='close-profile-button'
          className={styles.closeButton}
          LeftIcon={IconClose}
        />

        <UserProfileHeader
          userId={userId}
          avatar={profile?.avatar}
          username={profile?.username}
          lastActionTime={profile?.lastActionTime}
        />

        <div className='mt-12'>
          <UserProfileMainInfo
            userId={userId}
            username={profile?.username}
            city={profile?.city}
            isPrivate={isPrivate}
            isAdminProfile={isAdminProfile}
            statusText={profile?.profileStatusText}
            studentsGroup={profile?.studentsGroup}
            email={profile?.email}
          />
        </div>

        {isPrivate && (
          <div className='mt-24'>
            <PrivateProfileBadge />
          </div>
        )}

        {(profile?.latestTopic || profile?.latestTask) && (
          <div className='mt-32'>
            <CurrentProgress
              latestTask={profile?.latestTask}
              latestTopic={profile?.latestTopic}
            />
          </div>
        )}

        {profile?.leaderboardUser && (
          <div className='mt-32'>
            <Statistic
              leaderboardUser={profile.leaderboardUser}
            />
          </div>
        )}

        {(profile?.achievements?.length ?? 0) > 0 && (
          <div className='mt-32'>
            <Achievements
              achievements={profile?.achievements}
            />
          </div>
        )}

        {
          isAtLeastOneLinkExists && (
            <div className='mt-32'>
              <SocialLinks
                socialLinks={profile?.socialLinks}
              />
            </div>
          )
        }
      </div>
    </>
  );
});
