import React, { memo, FC } from 'react';
import { PlatformStatisticItem } from '@/components/ui/PlatformStatistic/PlatformStatistic.typedefs';
import { cn } from '@/lib';
import { typography } from '@/components/ui/typography';
import { StatisticItem } from '@/components/ui/PlatformStatistic/StatisticItem';
import styles from './PlatformStatistic.module.scss';

type Props = {
  heading: string;
  statisticItems: PlatformStatisticItem[];
  dataQa?: string;
};

export const PlatformStatistic: FC<Props> = memo((props) => {
  const {
    heading,
    statisticItems,
    dataQa = 'platform-statistic',
  } = props;

  return (
    <section data-qa={dataQa}>
      <h3 className={cn(typography.platformH3, styles.title)}>
        {heading}
      </h3>

      <ul
        className={styles.container}
      >
        {statisticItems.map((item) => (
          <StatisticItem item={item} key={item.id} />
        ))}
      </ul>
    </section>
  );
});
