import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { FCIcon } from './typedefs';

export const IconBadgeRoleModerator: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path
      d="M11.1667 5.85004L8.17083 5.59171L7 2.83337L5.82916 5.59587L2.83333 5.85004L5.10833 7.82087L4.425 10.75L7 9.19587L9.575 10.75L8.89583 7.82087L11.1667 5.85004ZM7 8.41671L5.43333 9.36254L5.84999 7.57921L4.46666 6.37921L6.29166 6.22087L7 4.54171L7.7125 6.22504L9.5375 6.38337L8.15416 7.58337L8.57083 9.36671L7 8.41671Z"
      fill={BADGE_ICON_COLOR}
    />
  </BaseIcon>
);
