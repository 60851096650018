import React, { memo, FC } from 'react';
import { cn } from '@/lib';
import { FCIcon } from '@/components/ui/icons/typedefs';
import {
  ICON_WITH_BACKGROUND_SIZES,
  ICON_WITH_BACKGROUND_STYLES_BY_COLOR,
} from '@/components/ui/IconWithBackground/constants';
import {
  IconWithBackgroundColor,
  IconWithBackgroundSize,
} from '@/components/ui/IconWithBackground/typedefs';
import styles from './IconWithBackground.module.scss';

type Props = {
  Icon?: FCIcon;
  iconElement?: React.ReactNode;
  color: IconWithBackgroundColor;
  className?: string;
  size?: IconWithBackgroundSize;
  dataQa?: string;
};

export const IconWithBackground: FC<Props> = memo((props) => {
  const {
    Icon,
    color,
    className,
    dataQa,
    size = IconWithBackgroundSize.Small,
  } = props;

  return (
    <div
      className={cn(
        styles.iconWrapper,
        ICON_WITH_BACKGROUND_STYLES_BY_COLOR[color],
        ICON_WITH_BACKGROUND_SIZES[size],
        className,
      )}
    >
      {Icon && <Icon dataQa={dataQa} />}
      {props.iconElement}
    </div>
  );
});
