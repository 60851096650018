import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_ICON_COLOR,
  BADGE_BACKGROUND_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { FCIcon } from './typedefs';

export const IconBadgePro: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="2 2 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="10" fill={BADGE_BACKGROUND_COLOR} />
    <path
      d="M8.02999 14.3387C7.89896 13.7668 7.83306 13.182 7.83356 12.5953C7.83356 9.56668 9.56154 6.97859 12.0002 5.9393C14.4389 6.97859 16.1669 9.56668 16.1669 12.5953C16.1669 13.1964 16.099 13.7804 15.9705 14.3387L17.1729 15.4078C17.2246 15.4538 17.2587 15.5164 17.2692 15.5849C17.2798 15.6533 17.2661 15.7233 17.2306 15.7828L15.7675 18.2208C15.7442 18.2596 15.7124 18.2925 15.6745 18.3171C15.6366 18.3417 15.5936 18.3574 15.5487 18.3629C15.5038 18.3684 15.4583 18.3636 15.4156 18.3489C15.3728 18.3342 15.334 18.3099 15.302 18.278L13.9604 16.9363C13.8487 16.8247 13.6974 16.762 13.5395 16.7619H10.4609C10.3031 16.762 10.1517 16.8247 10.0401 16.9363L8.69845 18.278C8.66648 18.3099 8.62765 18.3342 8.58491 18.3489C8.54217 18.3636 8.49664 18.3684 8.45178 18.3629C8.40691 18.3574 8.36389 18.3417 8.32598 18.3171C8.28807 18.2925 8.25626 18.2596 8.23297 18.2208L6.76987 15.7833C6.7342 15.7238 6.72045 15.6537 6.731 15.5851C6.74154 15.5166 6.77571 15.4538 6.82761 15.4078L8.02999 14.3387V14.3387ZM12.0002 12.5953C12.316 12.5953 12.6188 12.4698 12.842 12.2466C13.0653 12.0233 13.1907 11.7205 13.1907 11.4048C13.1907 11.089 13.0653 10.7862 12.842 10.563C12.6188 10.3397 12.316 10.2143 12.0002 10.2143C11.6845 10.2143 11.3817 10.3397 11.1584 10.563C10.9352 10.7862 10.8098 11.089 10.8098 11.4048C10.8098 11.7205 10.9352 12.0233 11.1584 12.2466C11.3817 12.4698 11.6845 12.5953 12.0002 12.5953Z"
      fill={BADGE_ICON_COLOR}
    />
  </BaseIcon>
);
