import { useMemo } from 'react';
import { MINUTE } from '@/constants';
import { exists } from '@/lib/helpers/functional';

export const useIsOnline = (
  lastActionTime?: Date | null,
): boolean => (
  useMemo(() => (
    exists(lastActionTime)
    && (
      new Date().getTime() - new Date(lastActionTime).getTime()
    ) < MINUTE * 5
  ), [lastActionTime])
);
