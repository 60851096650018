import React from 'react';
import { cn } from '@/lib/classNames';
import { IconLock } from '@/components/ui/icons/IconLock';
import { typography } from '@/components/ui/typography';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './PrivateProfileBadge.module.scss';

export const PrivateProfileBadge = React.memo(() => {
  const { t } = useTranslation([I18N_CODES.common]);

  return (
    <div
      className={styles.wrapper}
      data-qa={UserProfileHarnessSelectors.PrivateProfileBadge}
    >
      <div className={styles.iconWrapper}>
        <IconLock />
      </div>

      <h6 className={cn(styles.badge, typography.platformTextSecondary)}>
        {t(`${I18N_CODES.common}:user_profile.private_profile_badge`)}
      </h6>
    </div>
  );
});
