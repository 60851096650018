import { UserProfileSocialLinksFragment } from '@/components/platform/UserProfile/graphql/generated/profileSocialLinks.generated';
import { IconGithub } from '@/components/ui/icons/IconGithub';
import { IconLinkedIn } from '@/components/ui/icons/IconLinkedIn';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const SocialLinksIconsMap: Record<
  keyof Omit<UserProfileSocialLinksFragment, '__typename'>,
  FCIcon
> = {
  githubUrl: IconGithub,
  linkedin: IconLinkedIn,
};
