import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { FCIcon } from './typedefs';

export const IconBadgeProfessionDesign: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path d="M4.08333 4.29165C4.08333 3.90487 4.23697 3.53394 4.51046 3.26045C4.78395 2.98696 5.15489 2.83331 5.54166 2.83331H6.99999V5.74998H5.54166C5.15489 5.74998 4.78395 5.59633 4.51046 5.32284C4.23697 5.04935 4.08333 4.67842 4.08333 4.29165Z" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 2.83331H8.45833C8.64984 2.83331 8.83948 2.87103 9.01641 2.94432C9.19335 3.01761 9.35411 3.12503 9.48953 3.26045C9.62495 3.39587 9.73237 3.55663 9.80566 3.73357C9.87895 3.9105 9.91667 4.10014 9.91667 4.29165C9.91667 4.48316 9.87895 4.67279 9.80566 4.84973C9.73237 5.02666 9.62495 5.18742 9.48953 5.32284C9.35411 5.45826 9.19335 5.56568 9.01641 5.63897C8.83948 5.71226 8.64984 5.74998 8.45833 5.74998H7V2.83331Z" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 7.20833C7 7.01682 7.03772 6.82719 7.11101 6.65025C7.1843 6.47332 7.29172 6.31255 7.42714 6.17714C7.56255 6.04172 7.72332 5.9343 7.90025 5.86101C8.07719 5.78772 8.26682 5.75 8.45833 5.75C8.64984 5.75 8.83948 5.78772 9.01641 5.86101C9.19335 5.9343 9.35411 6.04172 9.48953 6.17714C9.62495 6.31255 9.73237 6.47332 9.80566 6.65025C9.87895 6.82719 9.91667 7.01682 9.91667 7.20833C9.91667 7.39984 9.87895 7.58948 9.80566 7.76641C9.73237 7.94335 9.62495 8.10411 9.48953 8.23953C9.35411 8.37495 9.19335 8.48237 9.01641 8.55566C8.83948 8.62895 8.64984 8.66667 8.45833 8.66667C8.26682 8.66667 8.07719 8.62895 7.90025 8.55566C7.72332 8.48237 7.56255 8.37495 7.42714 8.23953C7.29172 8.10411 7.1843 7.94335 7.11101 7.76641C7.03772 7.58948 7 7.39984 7 7.20833V7.20833Z" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.08333 10.125C4.08333 9.73819 4.23697 9.36725 4.51046 9.09376C4.78395 8.82027 5.15489 8.66663 5.54166 8.66663H6.99999V10.125C6.99999 10.5117 6.84635 10.8827 6.57286 11.1562C6.29937 11.4296 5.92844 11.5833 5.54166 11.5833C5.15489 11.5833 4.78395 11.4296 4.51046 11.1562C4.23697 10.8827 4.08333 10.5117 4.08333 10.125Z" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.08333 7.20833C4.08333 6.82156 4.23697 6.45063 4.51046 6.17714C4.78395 5.90365 5.15489 5.75 5.54166 5.75H6.99999V8.66667H5.54166C5.15489 8.66667 4.78395 8.51302 4.51046 8.23953C4.23697 7.96604 4.08333 7.59511 4.08333 7.20833Z" stroke={BADGE_ICON_COLOR} strokeWidth="0.833333" strokeLinecap="round" strokeLinejoin="round" />
  </BaseIcon>
);
