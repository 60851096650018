import { useEffect } from 'react';
import { MESSAGE_TYPES, MESSAGES } from '@/lib/constants/messages';
import { useFlashMessage } from '@/hooks/useFlashMessage';

export const useShowErrorMessage = (
  error: Error | null | undefined,
) => {
  const {
    showMessage,
  } = useFlashMessage();

  useEffect(() => {
    if (error) {
      showMessage({
        type: MESSAGE_TYPES.error,
        text: error?.message || undefined,
        code: error?.message
          ? undefined
          : MESSAGES.general.unexpectedError.code,
      });
    }
  }, [error, showMessage]);
};
