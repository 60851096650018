import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import { FormRenderProps } from 'react-final-form';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { Button } from '@/components/ui/Button';
import { Loader } from '@/components/ui/Loader';
import { InputTextArea } from '@/components/ui/FormElements/FormInputs/InputTextArea';
import { FormField } from '@/components/ui/FormElements/FormField';
import { UpdateStatusFormData } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/typedefs';
import { PROFILE_STATUS_MAX_LENGTH } from '@/components/platform/UserProfile/UserProfileMainInfo/constants';
import styles from './UpdateStatus.module.scss';

type Props = FormRenderProps<UpdateStatusFormData> & {
  cancelUpdate: () => void;
};

export const UpdateStatusForm: FC<Props> = ({
  handleSubmit, submitting, dirty, cancelUpdate,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onKeyDown = useCallback((
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    const isSendShortcutPressed = e.key === 'Enter' && !e.shiftKey;

    if (isSendShortcutPressed && handleSubmit) {
      handleSubmit(e);
    }
  }, [handleSubmit]);
  const { t } = useTranslation([I18N_CODES.common]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      textAreaRef.current.setSelectionRange(
        textAreaRef.current.value.length,
        textAreaRef.current.value.length,
      );
    }
  }, [textAreaRef]);

  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={handleSubmit}
      method="POST"
    >
      <div className="mb-12">
        <FormField
          renderInput={(inputProps) => (
            <InputTextArea
              textAreaRef={textAreaRef}
              rows={2}
              {...inputProps}
              placeholder={t(`${I18N_CODES.common}:user_profile.status_placeholder`)}
              data-qa='status-input'
              id='status'
              onKeyDown={onKeyDown}
              maxLength={PROFILE_STATUS_MAX_LENGTH}
            />
          )}
          disabled={submitting}
          name='status'
        />
      </div>

      <div className={styles.buttonsWrapper}>
        <Button
          className={styles.button}
          mode={Button.mode.Secondary}
          size={Button.size.Small}
          text={t(`${I18N_CODES.common}:user_profile.status_update_cancel_button`)}
          data-qa='cancel-status-update-button'
          disabled={submitting}
          onClick={cancelUpdate}
        />

        <Button
          type="submit"
          className={styles.button}
          data-qa='submit-status-update-button'
          mode={Button.mode.Primary}
          size={Button.size.Small}
          text={t(`${I18N_CODES.common}:user_profile.status_update_save_button`)}
          disabled={submitting || !dirty}
        />
      </div>

      <Loader
        loading={submitting}
        size={32}
      />
    </form>
  );
};
