import React, { useCallback } from 'react';
import { Image } from '@/components/ui/Image';
import { UserProfileCityFragment } from '@/components/platform/UserProfile/graphql/generated/profileCity.generated';
import { UserProfileStudentsGroupFragment } from '@/components/platform/UserProfile/graphql/generated/profileStudentsGroup.generated';
import { InfoItemsIconsMap, UserProfileMainInfoItem, UserProfileMainInfoItems } from '@/components/platform/UserProfile/UserProfileMainInfo/constants';
import { IconMateAcademy } from '@/components/ui/icons/IconMateAcademy';
import { rawImageLoader } from '@/components/ui/Image/utils/imageLoader';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { IconKnowely } from '@/components/ui/icons/IconKnowely';
import styles from './UserProfileMainInfo.module.scss';

export interface UseMainInfoListOptions {
  city?: UserProfileCityFragment | null;
  email?: string | null;
  studentsGroup?: UserProfileStudentsGroupFragment | null;
  isAdminProfile?: boolean | null;
}

export interface UseMainInfoList {
  (options: UseMainInfoListOptions): UserProfileMainInfoItem[];
}

export const useMainInfoList: UseMainInfoList = ({
  city, studentsGroup, isAdminProfile, email,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);

  const defaultIconRenderer = useCallback((item: UserProfileMainInfoItems) => {
    const Icon = InfoItemsIconsMap[item];

    return <Icon />;
  }, []);

  const { isKnowelyProduct } = useSubDomainContext();

  const AdminIcon = isKnowelyProduct
    ? <IconKnowely />
    : <IconMateAcademy />;

  const mainInfoList: UserProfileMainInfoItem[] = [];

  if (isAdminProfile) {
    mainInfoList.push({
      id: UserProfileMainInfoItems.Profession,
      title: t(`${I18N_CODES.common}:user_profile.admin_label`),
      renderIcon: () => AdminIcon,
    });
  }

  if (email) {
    mainInfoList.push({
      id: UserProfileMainInfoItems.Email,
      title: email,
      renderIcon: () => defaultIconRenderer(UserProfileMainInfoItems.Email),
    });
  }

  if (studentsGroup) {
    if (studentsGroup.profession) {
      mainInfoList.push({
        id: UserProfileMainInfoItems.Profession,
        title: studentsGroup.profession.professionName ?? '',
        renderIcon: () => {
          if (studentsGroup?.profession?.professionIcon) {
            return (
              <Image
                className={styles.professionIcon}
                src={studentsGroup?.profession?.professionIcon?.url || ''}
                width={16}
                height={16}
                alt={studentsGroup?.profession?.professionIcon?.name}
                objectFit="cover"
                loader={rawImageLoader}
              />
            );
          }

          return <IconMateAcademy />;
        },
      });
    }

    mainInfoList.push({
      id: UserProfileMainInfoItems.Group,
      title: studentsGroup.name,
      renderIcon: () => defaultIconRenderer(UserProfileMainInfoItems.Group),
    });
  }

  if (city) {
    const countryPart = city?.country
      ? `${city.country.googleName}, `
      : '';

    mainInfoList.push({
      id: UserProfileMainInfoItems.Location,
      title: `${countryPart}${city.googleName}`,
      renderIcon: () => defaultIconRenderer(UserProfileMainInfoItems.Location),
    });
  }

  return mainInfoList;
};
