import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';
import {
  UserAvatarFallbackHarness,
} from '@/components/ui/UserAvatar/components/UserAvatarFallback/UserAvatarFallback.harness';
import { UserAvatarBadgeHarness } from '@/components/ui/UserAvatar/components/UserAvatarBadge/UserAvatarBadge.harness';

export enum UserAvatarHarnessSelectors {
  DataQa = 'user-avatar',
  Image = 'user-avatar-image',
}

export class UserAvatarHarness extends ComponentHarness {
  dataQa = UserAvatarHarnessSelectors.DataQa;

  get image() {
    return this.getNestedElement(UserAvatarHarnessSelectors.Image);
  }

  get fallback() {
    return new UserAvatarFallbackHarness();
  }

  get badge() {
    return new UserAvatarBadgeHarness();
  }

  assertImageExists() {
    this.image.should('exist');
  }

  assertImageNotExists() {
    this.image.should('not.exist');
  }

  assertFallbackExists() {
    this.fallback.assertExists();
  }

  assertFallbackNotExists() {
    this.fallback.assertNotExist();
  }

  assertBadgeExists() {
    this.badge.assertExists();
  }

  assertBadgeNotExists() {
    this.badge.assertNotExist();
  }
}
