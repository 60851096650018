import React from 'react';
import { Button } from '@/components/ui/Button';
import { IconSend } from '@/components/ui/icons/IconSend';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { useGoToDirectChat } from '@/components/platform/UserProfile/hooks/useGoToDirectChat';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { GoToDirectChatSource } from '@/controllers/analytics/generated';
import styles from './DirectMessage.module.scss';

export const DirectMessage = React.memo(() => {
  const { userId } = useUserProfileContext();
  const { t } = useTranslation([I18N_CODES.common]);

  const {
    goToDirectChat,
    isLoading: isDirectChatLoading,
  } = useGoToDirectChat(
    userId ?? 0,
    GoToDirectChatSource.Profile,
  );

  return (
    <>
      <Button
        className={styles.button}
        LeftIcon={IconSend}
        disabled={isDirectChatLoading}
        size={Button.size.Small}
        onClick={goToDirectChat}
        mode={Button.mode.Secondary}
        text={t(`${I18N_CODES.common}:user_profile.send_message_button`)}
        data-qa={UserProfileHarnessSelectors.SendDmButton}
      />
    </>
  );
});
