import { IconBadgeProfessionQa } from '@/components/ui/icons/IconBadgeProfessionQa';
import { IconEmail } from '@/components/ui/icons/IconEmail';
import { IconGroup } from '@/components/ui/icons/IconGroup';
import { IconLocationFill } from '@/components/ui/icons/IconLocationFill';

export const PROFILE_STATUS_MAX_LENGTH = 80;

export enum UserProfileMainInfoItems {
  Profession = 'Profession',
  Group = 'Group',
  Location = 'Location',
  Email = 'Email',
}

export interface UserProfileMainInfoItem {
  id: UserProfileMainInfoItems;
  title: string;
  renderIcon: () => JSX.Element;
}

export const InfoItemsIconsMap = {
  [UserProfileMainInfoItems.Profession]: IconBadgeProfessionQa,
  [UserProfileMainInfoItems.Group]: IconGroup,
  [UserProfileMainInfoItems.Location]: IconLocationFill,
  [UserProfileMainInfoItems.Email]: IconEmail,
};

export const InfoItemsList = [
  UserProfileMainInfoItems.Profession,
  UserProfileMainInfoItems.Group,
  UserProfileMainInfoItems.Location,
];
