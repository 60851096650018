import { FullAchievementsList } from '@/components/platform/UserProfile/Views/FullAchievementsList';
import { MainView } from '@/components/platform/UserProfile/Views/MainView';
import { File } from '@/controllers/graphql/generated';
import { AvatarFile } from '@/components/ui/UserAvatar';

export enum UserProfileMode {
  DefaultView = 'DefaultView',
  FullAchievementsListView = 'FullAchievementsListView',
}

export const ViewsMap = {
  [UserProfileMode?.DefaultView]: MainView,
  [UserProfileMode.FullAchievementsListView]: FullAchievementsList,
};

export interface UserProfilePreview {
  userId: number;
  fullName: string;
  avatar: AvatarFile | null;
  lastActionTime: Date | null;
  occupation: string;
  occupationIcon?: File | null;
}

export enum UserProfileTypeNames {
  AdminProfile = 'AdminProfile',
  PublicUserProfile = 'PublicUserProfile',
  PrivateUserProfile = 'PrivateUserProfile',
}

export enum UserProfileOpener {
  Unspecified = 'Unspecified',
  TestTaskActivity = 'TestTaskActivity',
  ChatMention = 'ChatMention',
  ChatInfo = 'ChatInfo',
  ChatMessage = 'ChatMessage',
  ChatParticipantInfo = 'ChatParticipantInfo',
  ChatAddParticipant = 'ChatAddParticipant',
  PlatformSettings = 'PlatformSettings',
  ScheduleEventQuestion = 'ScheduleEventQuestion',
  ScheduleEventParticipant = 'ScheduleEventParticipant',
  ScheduleEventHost = 'ScheduleEventHost',
  EventSidebar = 'EventSidebar',
  UserProfilePreview = 'UserProfilePreview',
  MediaPreviewSlider = 'MediaPreviewSlider',
  GroupDashboard = 'GroupDashboard',
  HomeworkDashboard = 'HomeworkDashboard',
  LeaderboardTop = 'LeaderboardTop',
  LeaderboardUser = 'LeaderboardUser',
  GlobalSearch = 'GlobalSearch',
  ForumComment = 'ForumComment',
  DirectChatBanner = 'DirectChatBanner',
  AutomatedMessages = 'AutomatedMessagesTable',
  HomeworkReviews = 'HomeworkReviews',
}

export type WithProfileProps<BaseProps> = BaseProps & {
  shouldOpenProfile?: true;
  userProfileOpener: UserProfileOpener;
};

export type WithoutProfileProps<BaseProps> = BaseProps & {
  shouldOpenProfile: false;
  userProfileOpener?: never;
};

export type ProfileOpenerProps<BaseProps> = (
  WithProfileProps<BaseProps> | WithoutProfileProps<BaseProps>
);
