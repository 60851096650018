import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from './typedefs';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_ICON_COLOR,
} from '../UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';

export const IconBadgeUnlim: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="2 2 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="10" fill={BADGE_BACKGROUND_COLOR} />
    <path
      d="M8.42885 8.42859C6.08956 8.42859 4.85742 10.2322 4.85742 12C4.85742 13.7679 6.08956 15.5714 8.42885 15.5714C9.83956 15.5714 10.9646 14.5714 12.0003 13.3929C13.036 14.5714 14.1253 15.5714 15.5717 15.5714C17.911 15.5714 19.1431 13.7679 19.1431 12C19.1431 10.2322 17.911 8.42859 15.5717 8.42859C14.1253 8.42859 13.036 9.42859 12.0003 10.6072C10.9646 9.42859 9.83956 8.42859 8.42885 8.42859ZM8.42885 10.2143C9.17885 10.2143 10.0003 11.0536 10.8217 12C10.0003 12.9464 9.17885 13.7857 8.42885 13.7857C7.10742 13.7857 6.64314 12.8214 6.64314 12C6.64314 11.1786 7.10742 10.2143 8.42885 10.2143V10.2143ZM15.5717 10.2143C16.8931 10.2143 17.3574 11.1786 17.3574 12C17.3574 12.8214 16.8931 13.7857 15.5717 13.7857C14.8038 13.7857 13.9824 12.9464 13.1788 12C14.0003 11.0536 14.8038 10.2143 15.5717 10.2143Z"
      fill={BADGE_ICON_COLOR}
    />
  </BaseIcon>
);
