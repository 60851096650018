import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import {
  BADGE_ICON_COLOR,
  BADGE_BACKGROUND_COLOR,
} from '@/components/ui/UserAvatar/components/UserAvatarBadge/userAvatarBadge.utils/badge.constants';
import { FCIcon } from './typedefs';

export const IconBadgeRoleExternalMentor: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="7" cy="7" r="7" fill={BADGE_BACKGROUND_COLOR} />
    <path
      d="M9.49999 9.08331V11.1666H8.66666V9.08331C8.66666 7.22873 9.76833 5.63123 11.3529 4.90998L11.6979 5.66831C11.0424 5.96647 10.4865 6.44704 10.0968 7.05261C9.70703 7.65819 9.49985 8.36316 9.49999 9.08331ZM5.33333 9.08331V11.1666H4.49999V9.08331C4.50006 8.36323 4.29284 7.65835 3.90309 7.05286C3.51335 6.44736 2.95755 5.96686 2.30208 5.66873L2.64708 4.90998C3.4482 5.27435 4.1275 5.86163 4.60384 6.60167C5.08018 7.34171 5.33343 8.20322 5.33333 9.08331ZM6.99999 6.99998C6.44746 6.99998 5.91756 6.78049 5.52686 6.38979C5.13615 5.99908 4.91666 5.46918 4.91666 4.91665C4.91666 4.36411 5.13615 3.83421 5.52686 3.44351C5.91756 3.05281 6.44746 2.83331 6.99999 2.83331C7.55253 2.83331 8.08243 3.05281 8.47313 3.44351C8.86383 3.83421 9.08333 4.36411 9.08333 4.91665C9.08333 5.46918 8.86383 5.99908 8.47313 6.38979C8.08243 6.78049 7.55253 6.99998 6.99999 6.99998ZM6.99999 6.16665C7.16415 6.16665 7.32669 6.13431 7.47835 6.0715C7.63001 6.00868 7.76781 5.9166 7.88388 5.80053C7.99995 5.68446 8.09203 5.54666 8.15484 5.395C8.21766 5.24334 8.24999 5.0808 8.24999 4.91665C8.24999 4.75249 8.21766 4.58995 8.15484 4.43829C8.09203 4.28664 7.99995 4.14884 7.88388 4.03276C7.76781 3.91669 7.63001 3.82462 7.47835 3.7618C7.32669 3.69898 7.16415 3.66665 6.99999 3.66665C6.66847 3.66665 6.35053 3.79834 6.11611 4.03276C5.88169 4.26718 5.74999 4.58513 5.74999 4.91665C5.74999 5.24817 5.88169 5.56611 6.11611 5.80053C6.35053 6.03495 6.66847 6.16665 6.99999 6.16665Z"
      fill={BADGE_ICON_COLOR}
    />
  </BaseIcon>
);
