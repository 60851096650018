import React from 'react';
import { LeaderboardLeagueType } from '@/controllers/graphql/generated';
import { IconCupWithBackground } from '@/components/ui/icons/IconCupWithBackground';
import { CupMode } from '@/components/ui/icons/IconCup';
import { IconDiamondWithBackground } from '@/components/ui/icons/IconDiamondWithBackground';
import { IconLeaderboardNewbieLeague } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/icons/IconLeaderboardNewbieLeague';
import { IconLeaderboardLeadLeague } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/icons/IconLeaderboardLeadLeague';
import { IconLeaderboardFulltimeLeague } from '@/components/platform/Learn/CoursePageContent/components/LeaderboardBlockModule/components/icons/IconLeaderboardFulltimeLeague';

export const USER_PROFILE_LEAGUE_TYPE_CONTENT: Record<
  LeaderboardLeagueType,
{
  icon: JSX.Element;
  titleCode: string;
}
> = {
  [LeaderboardLeagueType.Bronze]: {
    icon: <IconCupWithBackground cupMode={CupMode.Bronze} />,
    titleCode: 'user_profile.leaderboard.league_bronze',
  },
  [LeaderboardLeagueType.Silver]: {
    icon: <IconCupWithBackground cupMode={CupMode.Silver} />,
    titleCode: 'user_profile.leaderboard.league_silver',
  },
  [LeaderboardLeagueType.Gold]: {
    icon: <IconCupWithBackground cupMode={CupMode.Gold} />,
    titleCode: 'user_profile.leaderboard.league_gold',
  },
  [LeaderboardLeagueType.Platinum]: {
    titleCode: 'user_profile.leaderboard.league_platinum',
    icon: <IconDiamondWithBackground />,
  },
  [LeaderboardLeagueType.Newbie]: {
    titleCode: 'user_profile.leaderboard.league_newbie',
    icon: <IconLeaderboardNewbieLeague />,
  },
  [LeaderboardLeagueType.Lead]: {
    titleCode: 'user_profile.leaderboard.league_lead',
    icon: <IconLeaderboardLeadLeague />,
  },
  [LeaderboardLeagueType.FullTime]: {
    titleCode: 'user_profile.leaderboard.league_fulltime',
    icon: <IconLeaderboardFulltimeLeague />,
  },
};
