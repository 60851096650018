import React from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { FCIcon } from '@/components/ui/icons/typedefs';

export const IconGithub: FCIcon = (props) => (
  <BaseIcon
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.9987 1.5C6.20138 1.5 1.5 6.32009 1.5 12.2661C1.5 17.0219 4.50825 21.0565 8.682 22.4813C9.207 22.5805 9.39863 22.2481 9.39863 21.9626C9.39863 21.7066 9.38944 21.0297 9.38419 20.1316C6.46388 20.7817 5.847 18.688 5.847 18.688C5.37056 17.4441 4.6815 17.113 4.6815 17.113C3.72731 16.4455 4.75237 16.4589 4.75237 16.4589C5.80631 16.5353 6.36019 17.5688 6.36019 17.5688C7.29731 19.2134 8.8185 18.7389 9.417 18.4628C9.5115 17.7671 9.78319 17.2926 10.0837 17.0232C7.75275 16.7511 5.301 15.8276 5.301 11.7031C5.301 10.5276 5.7105 9.56655 6.3825 8.81324C6.27356 8.54114 5.91394 7.44603 6.48488 5.96488C6.48488 5.96488 7.36688 5.67536 9.37238 7.06803C10.2098 6.82944 11.1075 6.71015 12.0013 6.70613C12.8925 6.71149 13.7916 6.82944 14.6302 7.06938C16.6344 5.6767 17.5151 5.96622 17.5151 5.96622C18.0874 7.44871 17.7277 8.54248 17.6201 8.81458C18.2934 9.56789 18.699 10.529 18.699 11.7045C18.699 15.8396 16.2446 16.7498 13.9057 17.0165C14.2824 17.3489 14.6184 18.0057 14.6184 19.0097C14.6184 20.4493 14.6053 21.6101 14.6053 21.9626C14.6053 22.2508 14.7943 22.5859 15.3272 22.48C19.4944 21.0538 22.5 17.0205 22.5 12.2661C22.5 6.32009 17.7986 1.5 11.9987 1.5Z" fill="currentColor" />
  </BaseIcon>
);
