import React from 'react';
import { FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';

export const IconSend: FCIcon = (props) => (
  <BaseIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.7604 13.4385C21.9011 12.8996 21.9075 11.279 20.7711 10.7311L4.53377 2.90261C3.38041 2.34654 2.10548 3.39808 2.4319 4.63618L4.00754 10.6124C4.03801 10.7366 4.12942 10.8374 4.25129 10.8773L9.7122 12.0655L4.25364 13.2538C4.13176 13.296 4.04036 13.3945 4.01223 13.5187L2.44304 19.3595C2.11142 20.5938 3.37674 21.6509 4.5324 21.1049L20.7604 13.4385ZM4.6022 18.9392L5.6522 14.8392L13.3602 12.7933C14.1044 12.5958 14.1034 11.5392 13.3587 11.3432L5.6722 9.31922L4.69916 5.40091L19.3022 12.096L4.6022 18.9392Z" fill="currentColor" />
  </BaseIcon>
);
