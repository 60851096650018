import React, { useCallback } from 'react';
import { cn } from '@/lib/classNames';
import { useUserProfileContext } from '@/components/platform/UserProfile/UserProfileContext';
import { UserProfileMode } from '@/components/platform/UserProfile/typedefs';
import { Button } from '@/components/ui/Button';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { IconClose } from '@/components/ui/icons/IconClose';
import { typography } from '@/components/ui/typography';
import { useGetUserProfileAllAchievementsQuery } from '@/components/platform/UserProfile/Views/FullAchievementsList/graphql/generated/getProfileFullAchievementsList.generated';
import { useProfile } from '@/components/platform/UserProfile/hooks/useProfile';
import { ICON_SIZE } from '@/components/platform/UserProfile/Views/FullAchievementsList/constants';
import { useAchievements } from '@/components/platform/UserProfile/AchievementsBlock/hooks/useAchievements';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import { AchievementItem } from '@/components/platform/Achievements';
import { Loader } from '@/components/ui/Loader';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './FullAchievementsList.module.scss';

export const FullAchievementsList = React.memo(() => {
  const { setMode, closeProfile, userId } = useUserProfileContext();
  const { t } = useTranslation([I18N_CODES.common]);

  const backToProfile = useCallback(() => {
    setMode(UserProfileMode?.DefaultView);
  }, [setMode]);

  const { data, loading } = useGetUserProfileAllAchievementsQuery({
    variables: {
      id: userId ?? 0,
    },
    skip: !userId,
  });

  const { profile } = useProfile(data?.getUserProfile);
  const { achievementsToProcess } = useAchievements({
    achievements: profile?.achievements ?? [],
    shouldShowAllAchievements: true,
  });

  return (
    <>
      <div
        className={styles.wrapper}
        data-qa={UserProfileHarnessSelectors.FullAchievementsListView}
      >
        <div className={styles.headerWrapper}>
          <div className={styles.titleWrapper}>
            <Button
              data-qa={UserProfileHarnessSelectors.AllAchievementsBackButton}
              mode={Button.mode.TransparentLight}
              size={Button.size.Small}
              LeftIcon={IconArrowLeft}
              onClick={backToProfile}
            />

            <h2 className={cn(typography.platformH2, styles.title)}>
              {t(`${I18N_CODES.common}:user_profile.full_achievements_list.title`)}
              {' '}
              <span className={cn(
                typography.platformTextSecondary,
                styles.titleCount,
              )}
              >
                {`(${achievementsToProcess.length})`}
              </span>
            </h2>
          </div>

          <Button
            className={styles.closeButton}
            mode={Button.mode.TransparentLight}
            size={Button.size.Small}
            LeftIcon={IconClose}
            onClick={closeProfile}
          />
        </div>

        <div className={cn(styles.achievementsList, 'mb-24')}>
          {achievementsToProcess?.map((achievementItem, i) => (
            <div
              className={styles.achievementWrapper}
              key={achievementItem.achievement.id || i}
            >
              <AchievementItem
                achievement={achievementItem.achievement}
                showNewBadge={false}
                iconWidth={ICON_SIZE}
                iconHeight={ICON_SIZE}
              />
            </div>
          ))}
        </div>
      </div>
      <Loader loading={!profile || loading} />
    </>
  );
});
