import React, { FC, useEffect, useState } from 'react';
import { Button } from '@/components/ui/Button';
import { IconCommentStatus } from '@/components/ui/icons/IconCommentStatus';
import { IconEdit } from '@/components/ui/icons/IconEdit';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { UpdateStatus } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import styles from './Status.module.scss';

interface Props {
  statusText?: string | null;
  isOwnProfile?: boolean;
  isAdminProfile?: boolean;
  userId?: number | null;
}

export const Status: FC<Props> = ({
  statusText,
  isOwnProfile,
  userId,
  isAdminProfile,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);
  const shouldShowSetStatusButton = (
    isOwnProfile
    && !isStatusUpdating
    && !statusText
  );

  useEffect(() => () => {
    if (!userId) {
      setIsStatusUpdating(false);
    }
  }, [setIsStatusUpdating, userId]);

  const shouldShowStatusForm = isStatusUpdating;

  return (
    <>
      {shouldShowStatusForm && (
        <UpdateStatus
          status={statusText}
          setIsStatusUpdating={setIsStatusUpdating}
          userId={userId}
          isAdminProfile={isAdminProfile}
        />
      )}

      {shouldShowSetStatusButton && (
        <Button
          className={cn(typography.platformH4, 'color-gray-90', styles.setStatusButton)}
          mode={Button.mode.Secondary}
          text={t(`${I18N_CODES.common}:user_profile.set_status_button`)}
          size={Button.size.Small}
          data-qa='set-status-button'
          LeftIcon={IconEdit}
          onClick={() => {
            setIsStatusUpdating(true);
          }}
        />
      )}

      {
        !shouldShowSetStatusButton
        && !shouldShowStatusForm
        && (
          <div className={styles.wrapper}>
            <p
              className={cn(
                styles.statusText,
                typography.platformTextSecondary,
              )}
              data-qa='user-status-text'
            >
              <IconCommentStatus className={styles.statusCommentIcon} />
              {statusText}
            </p>

            {isOwnProfile
              && (
                <div className={styles.editIconWrapper}>
                  <Button
                    mode={Button.mode.TransparentLight}
                    data-qa='edit-status-button'
                    RightIcon={IconEdit}
                    onClick={() => {
                      setIsStatusUpdating(true);
                    }}
                  />
                </div>
              )}
          </div>
        )
      }
    </>
  );
};
