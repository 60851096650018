import React, { FC } from 'react';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { SvgProps } from '@/components/ui/icons/typedefs';
import { CupMode } from '@/components/ui/icons/IconCup';

const CUP_WITH_BACKGROUND_COLORS_BY_TYPE = {
  [CupMode.Bronze]: {
    handleColor: '#AB6C4A',
    cupColor: '#EB9362',
    borderColor: '#D49A61',
    backGroundColor: '#E2B280',
    flareColor: '#F7D0A9',
  },
  [CupMode.Silver]: {
    handleColor: '#99A1A9',
    cupColor: '#A1A8AF',
    borderColor: '#A8AFB7',
    backGroundColor: '#CCD4DC',
    flareColor: '#DBE3EC',
  },
  [CupMode.Gold]: {
    handleColor: '#FFAC33',
    cupColor: '#FFCC4D',
    borderColor: '#FEC132',
    backGroundColor: '#FFEC92',
    flareColor: '#FFFDB8',
  },
};

interface CupIconProps {
  cupMode: CupMode;
}

export const IconCupWithBackground: FC<SvgProps & CupIconProps> = (props) => {
  const {
    cupMode,
    ...iconProps
  } = props;

  const {
    handleColor,
    cupColor,
    borderColor,
    backGroundColor,
    flareColor,
  } = CUP_WITH_BACKGROUND_COLORS_BY_TYPE[cupMode || CupMode.Bronze];

  // Used to prevent id conflicts when using this component multiple times on the same page
  const clipId = `clip_${Math.floor(Math.random() * 10000)}`;

  return (
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <circle
        cx="8"
        cy="7.99683"
        r="7.26748"
        fill={backGroundColor}
        stroke={borderColor}
        strokeWidth="1"
      />
      <path
        d="M2.1953 4.65945C1.7569 5.42172 1.47294 6.26286 1.35962 7.13487C1.24631 8.00687 1.30585 8.89266 1.53486 9.74165C1.76387 10.5906 2.15786 11.3862 2.69434 12.0829C3.23081 12.7797 3.89926 13.3639 4.66152 13.8023C5.42379 14.2407 6.26493 14.5247 7.13694 14.638C8.00894 14.7513 8.89473 14.6918 9.74372 14.4627C10.5927 14.2337 11.3883 13.8397 12.085 13.3033C12.7817 12.7668 13.366 12.0983 13.8044 11.3361L7.99984 7.99777L2.1953 4.65945Z"
        fill={flareColor}
      />
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M5.26343 5.23928H6.53887C6.77355 5.23928 6.93787 5.21717 6.93787 5.45185V5.02671C6.93787 4.79203 6.77355 4.60156 6.53887 4.60156H4.83829C4.41314 4.60156 4.07664 5.05328 4.20057 5.66443C4.20057 5.66443 4.58129 7.6573 4.60807 7.78123C4.67886 8.10902 5.05957 8.42788 5.46708 8.42788H6.94616C7.18105 8.42788 6.93787 8.02484 6.93787 7.79016V7.36501C6.93787 7.59969 6.77355 7.79016 6.53887 7.79016H5.68858C5.4539 7.79016 5.29893 7.56866 5.26343 7.36501C5.22793 7.16137 4.92672 5.80621 4.92672 5.80621C4.85593 5.39871 5.02854 5.23928 5.26343 5.23928ZM10.7504 5.23928H9.47493C9.24025 5.23928 8.85103 5.21717 8.85103 5.45185V5.02671C8.85103 4.79203 9.24025 4.60156 9.47493 4.60156H11.1755C11.6007 4.60156 11.9374 5.05328 11.8132 5.66443C11.8132 5.66443 11.4661 7.66665 11.4368 7.79016C11.3615 8.10902 11.0027 8.42788 10.5865 8.42788H9.06742C8.83274 8.42788 8.85103 8.02484 8.85103 7.79016V7.36501C8.85103 7.59969 9.24025 7.79016 9.47493 7.79016H10.3252C10.5599 7.79016 10.7151 7.56866 10.7504 7.36501C10.7857 7.16137 11.0871 5.80621 11.0871 5.80621C11.1577 5.39871 10.9853 5.23928 10.7504 5.23928ZM8.60274 8.85302C8.60274 7.37394 8.02688 8.85302 8.02688 8.85302C8.02688 8.85302 7.38916 7.37394 7.38916 8.85302C7.38916 10.3321 6.68958 10.9788 6.68958 10.9788H9.30253C9.30232 10.9788 8.60274 10.3321 8.60274 8.85302Z"
          fill={handleColor}
        />
        <path
          d="M10.3651 5.57576C10.3651 7.01594 8.92596 9.44289 8.02678 9.44289C7.12738 9.44289 5.68848 7.01594 5.68848 5.57576C5.68848 4.47145 5.90105 4.23251 6.3262 4.23251C6.61827 4.23251 7.60695 4.22678 8.02678 4.22678L9.51479 4.22656C10.1349 4.22635 10.3651 4.37196 10.3651 5.57576Z"
          fill={cupColor}
        />
        <path
          d="M9.91372 11.1924C9.91372 11.4271 9.96197 11.6176 9.72729 11.6176H6.32612C6.09123 11.6176 6.0874 11.4271 6.0874 11.1924V10.9798C6.0874 10.7452 6.33484 10.5547 6.55634 10.5547H9.51472C9.73622 10.5547 9.91372 10.7452 9.91372 10.9798V11.1924Z"
          fill="#C1694F"
        />
        <path
          d="M10.339 11.5368C10.339 11.6984 10.374 11.8291 10.0728 11.8291H5.98079C5.70614 11.8291 5.66235 11.6984 5.66235 11.5368V11.4837C5.66235 11.3221 5.81923 11.1914 5.98079 11.1914H10.0728C10.2342 11.1914 10.339 11.3221 10.339 11.4837V11.5368Z"
          fill="#C1694F"
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect
            width="7.65263"
            height="7.65263"
            fill="white"
            transform="translate(4.17432 4.17578)"
          />
        </clipPath>
      </defs>
    </BaseIcon>
  );
};
