import { useCallback } from 'react';
import { LogLevels } from '@mate-academy/logger';
import { UpdateStatusFormData } from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/typedefs';
import {
  useUpdateProfileStatusMutation,
} from '@/components/platform/UserProfile/UserProfileMainInfo/UpdateStatus/graphql/generated/updateProfileStatus.generated';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { analyticsSDK } from '@/controllers/analytics';
import { UserProfileTypeNames } from '@/components/platform/UserProfile/typedefs';
import { REQUIRED_RESPONSE_DATA_EMPTY } from '@/lib/constants/errors';
import { logger } from '@/core/Logger';
import { errorHandler } from '@/core/ErrorHandler';

interface Options {
  closeForm: () => void;
  userId?: number | null;
  isAdminProfile?: boolean;
}

export const useUpdateStatus = ({
  closeForm,
  userId,
  isAdminProfile,
}: Options) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const { showMessage } = useFlashMessage();
  const [updateStatus] = useUpdateProfileStatusMutation({
    optimisticResponse: (data) => ({
      updateProfile: {
        __typename: 'User',
        id: userId ?? 0,
        profileStatusText: data.status ?? '',
      },
    }),
    onError: (error) => {
      const errorNotification = t(`${I18N_CODES.common}:user_profile.error_updating_status`, {
        error: error.message,
      });

      showMessage({
        type: MESSAGE_TYPES.error,
        text: errorNotification,
      });
    },

    onCompleted: () => {
      analyticsSDK.userProfile.sendStatusUpdatedEvent({});

      showMessage({
        type: MESSAGE_TYPES.success,
        text: t(`${I18N_CODES.common}:user_profile.updating_status_success`),
      });

      closeForm();
    },
    update(cache, { data }) {
      const cacheId = cache.identify({
        __typename: isAdminProfile
          ? UserProfileTypeNames.AdminProfile
          : UserProfileTypeNames.PublicUserProfile,
        id: data?.updateProfile.id ?? 0,
      });

      if (!data) {
        errorHandler.captureMessage(REQUIRED_RESPONSE_DATA_EMPTY, {
          logLevel: LogLevels.Warning,
          logger: logger
            .child(useUpdateProfileStatusMutation.name)
            .child(useUpdateStatus.name),
        });

        return;
      }

      const { profileStatusText } = data.updateProfile;

      if (!profileStatusText) {
        return;
      }

      cache.modify({
        id: cacheId,
        fields: {
          profileStatusText: () => profileStatusText,
        },
      });
    },
  });

  const handleUpdate = useCallback((data: UpdateStatusFormData) => {
    updateStatus({
      variables: {
        status: data.status ?? '',
      },
    });
  }, [updateStatus]);

  return { handleUpdate };
};
