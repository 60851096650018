import React, { FC } from 'react';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib/classNames';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { Button } from '@/components/ui/Button';
import { SocialLinksIconsMap } from '@/components/platform/UserProfile/SocialLinksBlock/constants';
import { UserProfileSocialLinksFragment } from '@/components/platform/UserProfile/graphql/generated/profileSocialLinks.generated';
import { useClearLinks } from '@/components/platform/UserProfile/SocialLinksBlock/useClearLinks';
import { UserProfileHarnessSelectors } from '@/components/platform/UserProfile/UserProfile.harness';
import styles from './SocialLinks.module.scss';

interface Props {
  socialLinks?: UserProfileSocialLinksFragment | null;
}

export const SocialLinks: FC<Props> = ({
  socialLinks,
}) => {
  const { t } = useTranslation([I18N_CODES.common]);
  const links = useClearLinks(socialLinks);

  if (!links.length) {
    return null;
  }

  return (
    <div>
      <h3
        className={cn(typography.platformH2, styles.title)}
        data-qa={UserProfileHarnessSelectors.SocialLinksBlock}
      >
        {t(`${I18N_CODES.common}:user_profile.social_links.title`)}
      </h3>

      <div className={cn(styles.linksWrapper, 'mt-8')}>
        {links.map(([key, value]) => (
          <Button
            key={key}
            data-qa='added-social-link-button'
            mode={Button.mode.Secondary}
            RightIcon={SocialLinksIconsMap[key]}
            href={value}
            target='_blank'
          />
        ))}
      </div>
    </div>
  );
};
